import React, { useEffect } from 'react';
import TrippinladakhLogo from '../images/trippin-ladakh-logo-03.png';
import mdOfficelogo from '../images/Untitled-1-01.png';
import FaxtoneLogo from '../images/Faxtone logo.png';
import TavasLogo from '../images/TavasSecurity.png';
import VInfoNetLogo from '../images/Vision infonet logo (2).png';
import ConfluxLogo from '../images/Asset 2.png';
import MDCareLogo from '../images/MdCareLogo.png';
import '../css/Home.css';
import '../css/HomeMediaQuery.css';
export default function Clients() {
    useEffect(() => {
        const logoSlide = document.querySelector(".logos-slide");
        const copy = logoSlide.cloneNode(true);
        document.querySelector(".logo-slider").appendChild(copy);
    }, []);

    return (
        <div className='Clients Ourservice-heading' id='Our-Clients'>
            <h2>Our Clients</h2>
            <div className="logo-slider">
                <div className="logos-slide">
                    <img src={TrippinladakhLogo} alt="Trippin Ladakh" width="150px" height="80px"/>
                    <img src="https://livoncomfortsdemo.novatales.com/static/media/livon%20comfort%20logo.0c9d227ae805458b08ce.png" alt="Netflix" width="150px" height="80px"/>
                    <img src={mdOfficelogo} alt="Yelp" width="250px" height="auto" className='mdOffice'/>
                    <img src={FaxtoneLogo} alt="Adobe" width="250px" height="auto"/>
                    <img src={TavasLogo} alt="Ring" width="250px" height="auto"/>
                    <img src={VInfoNetLogo} alt="Nespresso" width="200px" height="auto"/>
                    <img src={ConfluxLogo} alt="Nespresso" width="250px" height="auto"/>
                    <img src={MDCareLogo} alt="Nespresso" width="250px" height="auto"/>
                </div>
            </div>
        </div>
    );
}
